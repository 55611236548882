import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Dive into product configurations, tweaking and applying them within your project environment.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Configurator</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Product Configure</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Optimize</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Viewer</AnchorLink>
    </AnchorLinks>
    <h2>{`Configurator`}</h2>
    <p>{`With Files uploaded into your Project environment, you can now explore the power of the KOPE platform.`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`Your project information`}</em></li>
      <li parentName="ol"><em parentName="li">{`Product Manager`}</em>{` - `}<em parentName="li">{`where you select a Supply Chain and then choose products from it for Apply.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Viewer`}</em>{` - `}<em parentName="li">{`View your model and applied products, toggle viewer settings and switch between different loaded models.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Output Panel`}</em>{` - `}<em parentName="li">{`Here you can see the results of Apply. Simple metrics show you the impact each product has when placed within your design.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Saves`}</em>{` - `}<em parentName="li">{`Studies allow you to set up design files and products for testing. When you are happy with the outcomes and wish to continue your design work, Save Result to lock in those choices for use and future review.`}</em></li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/19_configurator-map.png",
      "alt": "19_configurator-map.png"
    }}></img>
    <p>{`KOPE brings projects and products together. With that as our mission, we make it easy with these simple steps;`}</p>
    <h5><strong parentName="h5">{`Load a Project`}</strong></h5>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/20_configurator-load-a-project.png",
      "alt": "20_configurator-load-a-project.png"
    }}></img>
    <h5><strong parentName="h5">{`Choose a Product`}</strong></h5>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/21_configurator-choose-a-product.png",
      "alt": "21_configurator-choose-a-product.png"
    }}></img>
    <h5><strong parentName="h5">{`Apply`}</strong></h5>
    <p>{`Give KOPE a few seconds to work its magic and then explore the products placed within the project, all based on the inherent rules and requirements of those products.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/22_configurator-apply.png",
      "alt": "22_configurator-apply.png"
    }}></img>
    <h2>{`Product Configure`}</h2>
    <p>{`Now we know that projects have complex needs and products can be highly dynamic with many ways to be utilised. Within a Product in KOPE, you have access to the variables exposed by the manufacturer; all of those aspects of the product that can flex and adjust but still be fabricated.`}</p>
    <p>{`To explore what a Product can do, click the Configure button to see what can be tweaked.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/23_product-configure-configure-panel.png",
      "alt": "23_product-configure-configure-panel.png"
    }}></img>
    <p>{`Each product has a set of tabs organizing what can be adjusted. Make your changes and hit `}<inlineCode parentName="p">{`Save`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/24_product-configure-configure-products.png",
      "alt": "24_product-configure-configure-products.png"
    }}></img>
    <p>{`When you have tweaked your products sufficiently, hit `}<inlineCode parentName="p">{`Apply`}</inlineCode>{` again to see the outcomes. You can move between all variables safe in the knowledge that anything you choose is allowed by the manufacturer and can be built.`}</p>
    <p>{` `}{`  ✔️ `}<em parentName="p">{`As you test, the Outputs Panel on the right side of your screen updates with important information on how the placement has gone.`}</em></p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/25_product-configure-output-panel.png",
      "alt": "25_product-configure-output-panel.png"
    }}></img>
    <p>{`Clicking on Dashboard brings up a customizable screen where you can explore in deeper detail including tables, graphs and statistics.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/26_product-configure-dashboard.png",
      "alt": "26_product-configure-dashboard.png"
    }}></img>
    <p>{`With the outcomes you need for your project laid out, you can choose to Save Result to lock it down and come back later. Click `}<a parentName="p" {...{
        "href": "/getting-started/results"
      }}><strong parentName="a">{`here`}</strong></a>{` to see more information on Results.`}</p>
    <h2>{`Optimize`}</h2>
    <p>{`With KOPE, you can go further than placing products based on your choices. You can utilize the KOPE Optimization Engine to find optimal results against a wide array of goals and project targets.`}</p>
    <p>{`Firstly, set your Options count so that you can browse through to select your best outcome.`}</p>
    <p>{`Then to enable Optimization, you need to select variables of the product that you are happy to allow KOPE to flex. Scroll down and choose what works for you.`}</p>
    <p>{`Lastly, on the right side, decide what outcomes you are aiming for. Each product category will have different objectives to choose from. Select if you want to minimize or maximize each or ignore to leave out of consideration. Click `}<inlineCode parentName="p">{`Save`}</inlineCode>{` when finished.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/27_optimize-dashboard.png",
      "alt": "27_optimize-dashboard.png"
    }}></img>
    <p>{`When you are done, your chosen Product will show a blue icon letting you know it is set for optimizing.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/28_optimize-applied-products.png",
      "alt": "28_optimize-applied-products.png"
    }}></img>
    <p>{`Hit `}<inlineCode parentName="p">{`Apply`}</inlineCode>{` and let KOPE take care of the rest. When the run is complete, you will be offered a screen to explore the optimized options.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/29_optimize-optimized-results.png",
      "alt": "29_optimize-optimized-results.png"
    }}></img>
    <p>{`When you are happy with an option, you can make that your baseline by clicking `}<inlineCode parentName="p">{`Choose option`}</inlineCode>{`.`}</p>
    <h2>{`Viewer`}</h2>
    <p>{`The KOPE viewer matches many industry standards and has similar features to most viewers. Below is an overview of controls to manage how you interact and view your models.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/30_viewer-map.png",
      "alt": "30_viewer-map.png"
    }}></img>
    <ol>
      <li parentName="ol"><em parentName="li">{`Model Selector`}</em>{` - `}<em parentName="li">{`allowing you to change the base model you are applying products to.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Model Visibility`}</em>{` - `}<em parentName="li">{`Toggles to control visibility for each model, including base file and model per product applied.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Viewer Space`}</em>{` - `}<em parentName="li">{`Models will show here including view-box allowing you to move around fixed orthogonal views.`}</em></li>
      <li parentName="ol"><em parentName="li">{`View Control Bar`}</em>{` - `}<em parentName="li">{`Series of controls for viewer behaviour. See below for further information.`}</em></li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/31_viewer-toolbar.png",
      "alt": "31_viewer-toolbar.png"
    }}></img>
    <ol>
      <li parentName="ol"><em parentName="li">{`Camera positioning around content, including toggle for perspective/ortho camera.`}</em></li>
      <li parentName="ol"><em parentName="li">{`In-depth reviewing by sectioning and measuring elements.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Brings up the element information for selected items.`}</em></li>
      <li parentName="ol"><em parentName="li">{`Depper controls for exploring element and model data and broader array of viewer settings.`}</em></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      